window.onload = function() {
  if( document.getElementById('slideshow') ) {
    fadeSlides();
  }
};

modal();
signup();

function fadeSlides(){

  const slideshow = document.getElementById('slideshow');
  let slides = slideshow.querySelectorAll('.slide');
  let current = 0;
  let last = 0;
  let maxCount = slides.length;

  slides[current].classList.add('active');
  let timer = setInterval(sliding, 3000);

  // sliding();
  // setInterval(function(){
  //   // console.log('current: ' + current + ' length: ' + maxCount);
  //   // slides.classList.remove('active');
  //
  // }, 3000);

  function sliding(){

    console.log('slide');

    slides[current].classList.add('active');

    if(current <= (maxCount - 2)){
      current++;
      last = current - 1;
    }
    else{
      current = 0;
      last = maxCount - 1;
    }

    slides[last].classList.remove('active');

    setTimeout(function(){
      slides[current].classList.add('active');
    }, 200);

    slideshow.addEventListener("click", function(){

      if(current <= (maxCount - 2)){
        current++;
        last = current - 1;
      }
      else{
        current = 0;
        last = maxCount - 1;
      }

      slides[last].classList.remove('active');

      setTimeout(function(){
        slides[current].classList.add('active');
      }, 200);

      clearInterval(timer);
      timer = setInterval(sliding, 3000);

    });
  }
}

function modal(){
  const modal = document.getElementById('modal');
  const contactLink = document.querySelector('[data-link="contact"]');
  const subscribeLink = document.querySelector('[data-link="subscribe"]');
  const signup = document.getElementById('mc_embed_signup');

  let close = document.getElementsByClassName('close-button');
  contactLink.addEventListener('click', function(event){
    event.preventDefault();
    modal.classList.add('active');
    modal.querySelector('[data-modal="contact"]').classList.add('active');
  });
  subscribeLink.addEventListener('click', function(event){
    event.preventDefault();
    modal.classList.add('active');
    modal.querySelector('[data-modal="subscribe"]').classList.add('active');
  });
  for (var i = 0; i < close.length; i++) {
    close[i].addEventListener('click', function(event){
      modal.classList.remove('active');
      document.querySelector('.modal-content.active').classList.remove('active');
      if(signup){
        signup.classList.remove('subscribed');
      }
    });
  }
}

function signup(){
  const signup = document.getElementById('mc_embed_signup');
  const submit = document.getElementById('mc-embedded-subscribe');
  if(signup){
    submit.addEventListener('click', function(event){
      signup.classList.add('subscribed');
    });
  }
}
